import "core-js/modules/es.array.push.js";
import Waves from '@/utils/login_animation';
export default {
  data() {
    return {
      adImg: require('@/assets/image/icon-1.png'),
      loginForm: {
        username: '',
        password: '',
        checked: false
      },
      loading: false
    };
  },
  mounted() {
    if (!this.$w_fun.isMobile() && false) {
      this.$nextTick(() => {
        const waves = new Waves('#holder', {
          waves: 3,
          resize: true,
          amplitude: .7,
          width: 500,
          hue: [13, 13]
        });
        waves.animate();
      });
    }
  },
  methods: {
    handleLogin() {
      this.$store.dispatch('login', this.loginForm).then(r => {
        if (this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect);
        } else {
          this.$router.push('/index');
        }
      });
    }
  }
};